/* eslint-disable-file */
import React, { useState, useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import { titleCase } from 'title-case'
import Icon from '../Icons'

const SectionHeader = styled.p`
  padding: 0px 0px 0px 12px;
  font-size: 12px;
  font-family: 'CP Mono', 'Andale Mono', 'Lucida Console', monospace;
  text-transform: uppercase;
  color: rgb(112, 112, 112) !important;
`

const CollapseIcon = css`
  margin-left: auto;
  margin-right: 15px;
`

const SectionButton = css`
  border: 0;
  background: transparent;
  width: 100%;
  font-size: 13px;
  line-height: 14px;
  color: #111a2b;
  font-family: 'Fira Sans', 'Lucida Grande', 'Trebuchet MS', sans-serif;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding: 9px 0px 9px 2px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgb(220, 219, 218);
  }
`

const SectionButtonOpen = css`
  border: 0;
  background-color: #fff;
  width: 100%;
  font-size: 13px;
  line-height: 14px;
  color: #294fff;
  font-family: 'Fira Sans', 'Lucida Grande', 'Trebuchet MS', sans-serif;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding: 9px 0px 9px 2px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgb(220, 219, 218);
  }
`

const Article = ({ link, isCurrent, navSection, type }) => {
  const inline = css`
    padding-left: 20px;
    padding-right: 5px;
  `
  const dropdown = css`
    border: none;
    font-weight: ${isCurrent ? 'bold' : 'normal'};
    background: transparent;

    &:hover {
      background: transparent;
      font-weight: 800;
    }

    &:hover span:not(:hover) {
      background-color: #ff495c;
    }
  `
  const BULLET = '\u2022 '
  const dot = css`
  &::before {
    content: "${BULLET}";  
    color: #ff495c; 
    font-weight: bold; 
    font-size: 13px
    display: inline-block; 
  }
  `

  let moreCSS
  let dotCSS

  if (type === 'inline') {
    moreCSS = inline
  }

  if (type === 'dropdown') {
    moreCSS = dropdown
    dotCSS = dot
  }

  function isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect()
    const elemTop = rect.top
    const elemBottom = rect.bottom

    // Only completely visible elements return true:
    const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight
    // Partially visible elements return true:
    // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible
  }
  useEffect(() => {
    const el = document.getElementsByClassName('selected-link')[0]

    if (isCurrent) {
      if (!isScrolledIntoView(el)) {
        el.scrollIntoView({ block: 'start' })
      } else {
        navSection.current.scrollIntoView({ block: 'start' })
      }
    }
    // refNavSection.current.scrollTop += 10
    // eslint-disable-next-line
  }, [isCurrent]) // Only re-run the effect if count changes

  const className = isCurrent ? '' : 'selected-link'

  return (
    <li
      className={className}
      css={css`
        display: flex;
        list-style: none;
        align-items: center;
        list-style: none;
        margin-bottom: 1px;
        font-size: 13px;
        position: relative;
        margin-right: 15px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: 4px solid ${isCurrent ? '#284fff' : 'transparent'};
        font-family: 'fira sans', sans-serif;
        background: ${isCurrent ? '#dcdbda' : 'transparent'};
        transition: 0.3s;

        &:hover {
          background: #dcdbda;
        }

        ${dotCSS}
        ${moreCSS}
      `}
    >
      <Link
        css={css`
          padding: 9px 5px 9px 20px;
          width: 100%;
          color: #444444;
          text-decoration: none;
        `}
        to={link.url}
      >
        {titleCase(link.name)}
      </Link>
    </li>
  )
}

const InlineArticle = ({ name, links, location, navSection }) => {
  const linkItems = links.map((link) => {
    const isCurrent = `${link.url}` === location.pathname

    return (
      <Article
        key={link.name + link.url}
        link={link}
        type="inline"
        isCurrent={isCurrent}
        navSection={navSection}
      />
    )
  })
  return (
    <li>
      <SectionHeader
        css={css`
          padding: 0px 0px 0px 24px;
          color: #707070;
          border: none;
        `}
      >
        {name}
      </SectionHeader>
      <ul>{linkItems}</ul>
    </li>
  )
}

const getArticle = (link, location, navSection) => {
  const isCurrent = `${link.url}` === location.pathname

  return (
    <Article
      key={link.name + link.url}
      link={link}
      isCurrent={isCurrent}
      navSection={navSection}
    />
  )
}

const DropdownArticle = ({ name, url, links, slug, location, navSection }) => {
  const variants = {
    open: { display: 'inline-table' },
    closed: { display: 'none' },
  }
  /**
   * https://stackoverflow.com/questions/32354056/regex-to-find-string-between-slashes
   *
   * Finds the word between the first and second slashes, in our case the "slug"
   */
  const re = new RegExp('^/[^/]+/([^/]+)/')

  let isCurrentNav = false

  if (re.exec(location.pathname)) {
    isCurrentNav = re.exec(location.pathname)[1] === slug
  } else {
    isCurrentNav = location.pathname.indexOf(slug) !== -1
  }

  const linkItems = links.map((link) => {
    const isCurrent = `${link.url}` === location.pathname
    return (
      <Article
        key={link.name + link.url}
        link={link}
        type="dropdown"
        navSection={navSection}
        isCurrent={isCurrent}
      />
    )
  })

  return (
    <>
      <li
        css={css`
          display: flex;
          list-style: none;
          align-items: center;
          list-style: none;
          margin-bottom: 1px;
          font-size: 13px;
          position: relative;
          margin-right: 15px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-left: 4px solid ${isCurrentNav ? '#284fff' : 'transparent'};
          font-family: 'fira sans', sans-serif;
          background: ${isCurrentNav ? '#dcdbda' : 'transparent'};
          transition: 0.3s;

          &:hover {
            background: #dcdbda;
            color: pink;
          }
        `}
      >
        <Link
          css={css`
            width: 100%;
            padding: 9px 0px 9px 20px;
            color: #444444;
            text-decoration: none;
          `}
          to={url}
        >
          {name}
        </Link>
      </li>
      <motion.ul
        transition={{ type: 'spring', damping: 800 }}
        animate={isCurrentNav ? 'open' : 'closed'}
        variants={variants}
        css={css`
          width: 100%;
          margin-top: 5px !important;
          margin-left: 30px;
        `}
      >
        {linkItems}
      </motion.ul>
    </>
  )
}

const SectionDropdown = ({ node, sectionData }) => {
  const { icon, slug, name } = node
  const refNavSection = useRef()
  const currentNav = sectionData.location.pathname.indexOf(slug) === 1
  const [open, setOpen] = useState(currentNav)
  const toggle = () => {
    setOpen(!open)
  }

  const linkItems = node.links.map((link) => {
    if (!link.links)
      return getArticle(link, sectionData.location, refNavSection)
    if (!link.slug) {
      return (
        <InlineArticle
          key={link.name + link.url}
          name={link.name}
          url={link.url}
          links={link.links}
          navSection={refNavSection}
          location={sectionData.location}
        />
      )
    }
    return (
      <DropdownArticle
        key={link.slug + link.url}
        name={link.name}
        post={sectionData.post}
        slug={link.slug}
        navSection={refNavSection}
        location={sectionData.location}
        url={link.url}
        links={link.links}
      />
    )
  })

  const variants = {
    open: { display: 'inline-table' },
    closed: { display: 'none' },
  }

  return (
    <div
      css={css`
        display: inline-grid;
      `}
      ref={refNavSection}
    >
      <button
        onKeyPress={toggle}
        onClick={toggle}
        type="button"
        css={css`
          ${open ? SectionButtonOpen : SectionButton};
        `}
      >
        <Icon glyph={icon} placement={open ? 'blue' : 'nav'} />
        <div
          css={css`
            text-align: left;
          `}
        >
          {name}
        </div>
        <div
          css={css`
            ${CollapseIcon}
          `}
        >
          <FontAwesomeIcon
            size="1x"
            icon={open ? faAngleDown : faAngleRight}
            color="#444444"
          />
        </div>
      </button>

      <motion.ul
        css={css`
          margin-bottom: 0px;
          background-color: #fff;
          width: 100%;
        `}
        transition={{ type: 'spring', damping: 800 }}
        animate={open ? 'open' : 'closed'}
        variants={variants}
      >
        {linkItems}
      </motion.ul>
    </div>
  )
}

export const MainNavigation = ({ sections, location, post }) => {
  const slugMap = sections.reduce(function (object, section) {
    if (object[section.node.slug]) throw Error('Duplicate Nav Keys')
    object[section.node.slug] = section.node
    return object
  }, {})

  const sectionData = { location, post, slugMap }

  return (
    <>
      <SectionHeader>Getting Started</SectionHeader>
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['getting-started']}
      />
      <SectionDropdown sectionData={sectionData} node={slugMap['cpf']} />
      <SectionDropdown sectionData={sectionData} node={slugMap['templates']} />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['template-library']}
      />

      <SectionDropdown sectionData={sectionData} node={slugMap['guides']} />
      <SectionHeader>Platform</SectionHeader>
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['applications']}
      />
      <SectionDropdown sectionData={sectionData} node={slugMap['devices']} />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['data-tables']}
      />
      <SectionDropdown sectionData={sectionData} node={slugMap['dashboards']} />
      <SectionDropdown sectionData={sectionData} node={slugMap['notebooks']} />
      <SectionDropdown sectionData={sectionData} node={slugMap['workflows']} />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['edge-compute']}
      />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['experiences']}
      />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['organizations']}
      />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['instance-manager']}
      />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['user-accounts']}
      />
      <SectionDropdown sectionData={sectionData} node={slugMap['references']} />
      <SectionHeader>Education</SectionHeader>
      <SectionDropdown sectionData={sectionData} node={slugMap['university']} />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['workflow-lab']}
      />
      <SectionDropdown
        sectionData={sectionData}
        node={slugMap['template-tester']}
      />
      <SectionHeader>Resources</SectionHeader>
      <SectionDropdown sectionData={sectionData} node={slugMap['mqtt']} />
      <SectionDropdown sectionData={sectionData} node={slugMap['cli']} />
      <SectionDropdown sectionData={sectionData} node={slugMap['rest-api']} />
    </>
  )
}
